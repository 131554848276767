.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: url("../../../../public/assets/images/ol_bgr.jpg");
  background-size: cover;
  background-position: center;
}

.not-found-page h1 {
  font-size: 45px;
  color: white;
}

.not-found-page h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 17px;
  margin: 40px 0 70px;
  text-transform: uppercase;
}

.not-found-page .logo {
  max-width: 50%;
}
