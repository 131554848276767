.login-page {
  height: 100vh;
  width: 100%;
  background-image: url("../../../../public/assets/images/ol_bgr.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-container {
  margin-top: -5%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-left: -39px;
}

.login-page .logo {
  height: auto;
  width: 300px;
}

.login-page-content {
  width: calc(100% - 39px);
  margin-top: 20%;
  margin-left: auto;
}

.login-page .form {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.login-page .sub-heading {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 17px;
  margin: 40px 0 70px 0;
  color: white;
  font-weight: 400;
}

.login-page .password-input {
  flex: 1;
  min-width: 0;
  height: 40px;
  text-align: left;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  background-color: rgba(18, 21, 36, 0.4);
  color: white;
  font-size: 16px;
  letter-spacing: 2px;
  /* border with linear gradient */
  background: linear-gradient(#111624, #111624) padding-box,
    linear-gradient(to bottom, #394357, #444454) border-box;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.login-page .password-input:focus,
.login-page .password-input:hover,
.login-page .password-input:active {
  outline: none;
}

.login-page .password-input::placeholder {
  font-size: 8px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  color: #747c91;
  position: relative;
  line-height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.login-page .submit {
  width: 50px;
  height: 40px;
  padding: 10px;
  border: none;
  text-transform: uppercase;
  background-color: #040507;
  color: white;
  font-size: 13px;
  /*transition: all 0.25s ease-out;*/
  /* border with linear gradient */
  border: 2px solid transparent;
  border-radius: 5px;
  background: linear-gradient(to right, #111624, #111624),
    linear-gradient(to right top, #8472bc, #4f85c0);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /* --- */
}

.login-page .submit:hover,
.login-page .submit:focus,
.login-page .submit:active {
  cursor: pointer;
  background: linear-gradient(to right top, #8472bc, #4f85c0);
  border: 0;
}

/* MEDIA QUERIES */

@media screen and (min-width: 1800px) {
  .login-page .logo {
    height: auto;
    width: 350px;
  }

  .login-page-container {
    margin-left: -45px;
  }

  .login-page-content {
    width: calc(100% - 45px);
  }
}

@media screen and (min-width: 2200px) {
  .login-page .logo {
    height: auto;
    width: 400px;
  }

  .login-page-container {
    margin-left: -52px;
  }

  .login-page-content {
    width: calc(100% - 52px);
  }
}

@media screen and (min-width: 2400px) {
  .login-page .logo {
    height: auto;
    width: 450px;
  }

  .login-page-container {
    margin-left: -58px;
  }

  .login-page-content {
    width: calc(100% - 58px);
  }
}
